import * as yup from "yup";
import { EMAIL_REGEX } from "../utils/utils.service";

export const AddNotificationSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your name"),
  lastName: yup.string(),
  email: yup
    .string()
    .email("Please enter a valid email")
    .matches(EMAIL_REGEX, "Please enter a valid email"),
  phoneNumber: yup.string().required("Please enter your phone number").min(12),
  role: yup.string().required(),
  callbackRequest: yup.boolean().required(),
  dealerAssistant: yup.boolean().required(),
  appointments: yup.boolean().required(),
  hotLeads: yup.boolean().required(),
  firstResponder: yup.boolean().required(),
  newLeads: yup.boolean().required(),
  callbackRequestEmail: yup.boolean().required(),
  dealerAssistantEmail: yup.boolean().required(),
  appointmentsEmail: yup.boolean().required(),
  hotLeadsEmail: yup.boolean().required(),
  firstResponderEmail: yup.boolean().required(),
  newLeadsEmail: yup.boolean().required(),
});
