export default Object.freeze({
  CHAT_DROPZONE_ACCEPTED_FILES: "image/*,application/pdf",
  MAX_FILE_SIZE: 5 * 1024 * 1024,
  TIMESTAMP: 30 * 60 * 1000,
});

export const navHeight = 8;
export const navHeightPx = "64px";
export const navAndBottomHeightPx = "120px";
export const bottomHeight = 56;
