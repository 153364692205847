import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Radio from "@mui/material/Radio";
import ControlledInput from "../../components/input/controlled-input";
import MuiPhone from "../../components/input/mui-phone-input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddNotificationSchema } from "../../schemas/add-notification.schema";
// import { areObjectsEqual } from "../../utils/utils.service";
import { Button } from "../../components";

const notifOptions = [
  { name: "callbackRequest", label: "Callback Request" },
  { name: "dealerAssistant", label: "Dealership Assistant Request" },
  { name: "appointments", label: "Appointment" },
  { name: "hotLeads", label: "Hot Leads" },
  { name: "firstResponder", label: "First Responder" },
  { name: "newLeads", label: "New Leads" },
];

interface FormInputs {
  firstName: string;
  lastName?: string;
  email?: string;
  phoneNumber: string;
  role: string;
  callbackRequest: boolean;
  dealerAssistant: boolean;
  appointments: boolean;
  hotLeads: boolean;
  firstResponder: boolean;
  newLeads: boolean;
  callbackRequestEmail: boolean;
  dealerAssistantEmail: boolean;
  appointmentsEmail: boolean;
  hotLeadsEmail: boolean;
  firstResponderEmail: boolean;
  newLeadsEmail: boolean;
}

export default function AddNotification({
  setState,
  notificationDefaultValues,
  oBlockChecker,
  removeNotification,
  showRemoveButton,
  localId,
  notification,
}: any) {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    getFieldState,
    setValue,
  } = useForm<FormInputs>({
    defaultValues: useMemo(() => {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        role,
        callbackRequest,
        dealerAssistant,
        appointments,
        hotLeads,
        firstResponder,
        newLeads,
        callbackRequestEmail = false,
        dealerAssistantEmail = false,
        appointmentsEmail = false,
        hotLeadsEmail = false,
        firstResponderEmail = false,
        newLeadsEmail = false,
      } = notification;
      return {
        firstName,
        lastName,
        email,
        phoneNumber,
        role,
        callbackRequest,
        dealerAssistant,
        appointments,
        hotLeads,
        firstResponder,
        newLeads,
        callbackRequestEmail,
        dealerAssistantEmail,
        appointmentsEmail,
        hotLeadsEmail,
        firstResponderEmail,
        newLeadsEmail,
      };
    }, [notification]),
    mode: "onChange",
    resolver: yupResolver(AddNotificationSchema),
  });

  const emailInput = getValues("email");
  const hasEmailError = getFieldState("email").error;

  useEffect(() => {
    if (!notification.email) {
      setValue("newLeadsEmail", false);
      setValue("firstResponderEmail", false);
      setValue("hotLeadsEmail", false);
      setValue("appointmentsEmail", false);
      setValue("dealerAssistantEmail", false);
      setValue("callbackRequestEmail", false);
    }
  }, [notification.email, setValue]);

  useEffect(() => {
    const subscription = watch(
      ({
        firstName,
        phoneNumber,
        lastName,
        email,
        role,
        callbackRequest,
        dealerAssistant,
        appointments,
        hotLeads,
        firstResponder,
        newLeads,
        callbackRequestEmail,
        dealerAssistantEmail,
        appointmentsEmail,
        hotLeadsEmail,
        firstResponderEmail,
        newLeadsEmail,
      }: any) => {
        const updatedNotif = {
          ...notification,
          firstName,
          phoneNumber,
          lastName,
          role,
          email,
          callbackRequest,
          dealerAssistant,
          appointments,
          hotLeads,
          firstResponder,
          newLeads,
          callbackRequestEmail: email ? callbackRequestEmail : false,
          dealerAssistantEmail: email ? dealerAssistantEmail : false,
          appointmentsEmail: email ? appointmentsEmail : false,
          hotLeadsEmail: email ? hotLeadsEmail : false,
          firstResponderEmail: email ? firstResponderEmail : false,
          newLeadsEmail: email ? newLeadsEmail : false,
        };
        setState((prevNotifis: any) =>
          prevNotifis.map((prevItem: any) =>
            prevItem.localId === localId ? updatedNotif : prevItem
          )
        );
        // if (notificationDefaultValues.length !== updatedNotif.length) oBlockChecker(true);
        // else if (notificationDefaultValues.length === 1) {
        //   const isEqual = areObjectsEqual(notificationDefaultValues[0], updatedNotif[0]);
        //   oBlockChecker(!isEqual);
        // } else {
        //   const isEqual1 = areObjectsEqual(notificationDefaultValues[0], updatedNotif[0]);
        //   const isEqual2 = areObjectsEqual(notificationDefaultValues[1], updatedNotif[1]);
        //   oBlockChecker(isEqual1 && isEqual2 ? false : true);
        // }
      }
    );
    return () => subscription.unsubscribe();
  }, [localId, notification, notificationDefaultValues, oBlockChecker, setState, watch]);

  return (
    <Box>
      <Grid container justifyContent={"left"} spacing={3}>
        <Grid
          item
          xs={12}
          lg={7}
          display="flex"
          flexDirection="column"
          gap="20px"
          paddingBottom={"24px"}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <ControlledInput
                    error={errors.firstName && errors.firstName.message}
                    id="firstName"
                    label="First Name *"
                    placeholder="Enter your first name"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <ControlledInput
                    error={errors.lastName && errors.lastName.message}
                    id="lastName"
                    label="Last Name"
                    placeholder="Enter your last name"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <MuiPhone
                    // value={state?.phone_number}
                    label={"Phone Number *"}
                    id="phoneNumber"
                    placeholder="Enter your phone number"
                    error={
                      // notification.phone_number.length > 0 &&
                      // !validateMobileNumber(notification.phone_number)
                      errors.phoneNumber && errors.phoneNumber.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <ControlledInput
                      error={field.value && errors.email && errors.email.message}
                      id="email"
                      label="Email"
                      placeholder="Enter email"
                      type="email"
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <FormLabel>
                <Typography fontWeight={500} variant="body1">
                  Role:
                </Typography>
              </FormLabel>
              <RadioGroup
                title="rol"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                sx={{ flexDirection: "row" }}
              >
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Radio value={"owner"} />}
                      label="Owner"
                      checked={notification.role === "owner"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Radio value={"staff"} />}
                      label="Staff"
                      checked={notification.role === "staff"}
                      {...field}
                    />
                  )}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box display={"flex"} alignItems={"baseline"} flexDirection={"column"}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingRight: "16px",
                marginBottom: "8px",
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                Notification Option
              </Typography>
              <Box
                sx={{
                  width: "100px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" fontWeight={500}>
                  Text
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  Email
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                // display: "grid",
                // gridTemplateColumns: "repeat(2,auto)",
                // alignItems: "self-end",
                // alignSelf: "stretch",
              }}
            >
              {/* {notifOptions.map((option: any) => (
                <Controller
                  key={option.name}
                  control={control}
                  name={option.name}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox />}
                      label={option.label}
                      labelPlacement="end"
                      checked={notification[option.name]}
                      {...field}
                    />
                  )} */}
              {notifOptions.map((option: any) => (
                <Box
                  key={option.name}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "34px",
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    {option.name}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                    <Controller
                      key={option.name + "SMS"}
                      control={control}
                      name={option.name as keyof FormInputs}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label={""}
                          labelPlacement="end"
                          checked={notification[option.name]}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      key={option.name + "Email"}
                      disabled={!emailInput || hasEmailError ? true : false}
                      control={control}
                      name={(option.name + "Email") as keyof FormInputs}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox checked={!!field.value} onChange={field.onChange} />}
                          // checked={notification[option.name + "Email"]}
                          label={""}
                          labelPlacement="end"
                          {...field}
                        />
                      )}
                    />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => setValue('',e.target.checked)}
                          checked={notification[option.name + "Email"]}
                        />
                      }
                      label={""}
                      labelPlacement="end"

                      // {...field}
                    /> */}
                  </Box>
                </Box>
                // <Controller
                //   key={option.name}
                //   control={control}
                //   name={option.name}
                //   render={({ field }) => (
                //     <FormControlLabel
                //       control={<Checkbox />}
                //       label={option.label}
                //       labelPlacement="end"
                //       checked={notification[option.name]}
                //       {...field}
                //     />
                //   )}
                // />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {showRemoveButton && (
        <Box display={"flex"}>
          <Button variant="outlined" onClick={() => removeNotification(localId)}>
            Ignore
          </Button>
        </Box>
      )}
      <Divider sx={{ my: 2 }} />
      {/* {state?.filter((item: any) => !item.willBeRemoved).length > 1 && index === 0 && (
        <Divider sx={{ my: 2 }} />
      )} */}
    </Box>
  );
}
