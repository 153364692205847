import React, { memo, useEffect } from "react";
import { useBlocker } from "react-router";
import BlockDialog from "./dialog/blocker-dialog";

const UseBlockerHandler = ({ status }: any) => {
  const blocker: any = useBlocker(status);

  useEffect(() => {
    if (!status) return;
    const handleClick = (e: { preventDefault: () => void }) => {
      e.preventDefault();
    };
    // Attach the event listener to block navigation
    window.addEventListener("beforeunload", handleClick);
    return () => {
      // Cleanup the event listener
      window.removeEventListener("beforeunload", handleClick);
    };
  }, [status]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      // Show a confirmation dialog to the user
      //   const shouldProceed = window.confirm(
      //     "You have unsaved changes. Are you sure you want to leave?"
      //   );
      //   if (shouldProceed) {
      //     blocker.proceed();
      //   }
    }
  }, [blocker]);
  return (
    <BlockDialog
      open={blocker.state === "blocked"}
      handleClose={() => console.log("handleClose")}
      onAccept={() => {
        if (blocker) blocker?.proceed();
      }}
      onCancel={() => {
        if (blocker) blocker?.reset();
      }}
    />
  );
};

export default memo(UseBlockerHandler);
