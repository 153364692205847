import React from "react";
import Dialog from "./dialog";
import { Box } from "@mui/material";
import Button from "../button/button";

export default function BlockDialog({ open, onAccept, onCancel }: any) {
  return (
    <Dialog
      smallContent
      maxWidth={"xs"}
      renderTitle="Alert"
      renderContent="You have unsaved changes. Do you want to leave this page?"
      renderActions={
        <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
          <Button variant="contained" color="primary" onClick={onCancel}>
            No
          </Button>
          <Button variant="outlined" color="primary" onClick={onAccept}>
            Yes
          </Button>
        </Box>
      }
      open={open}
      handleClose={onCancel}
    />
  );
}
