import { Alert, Box, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "../../components";
import ControlledBox from "../../components/box/controlled-box";
import AddNotification from "./add-notification";
// import { areObjectsEqual } from "../../utils/utils.service";

export default function Notifications({
  state,
  setState,
  onMorePerson,
  removeNotification,
  notificationDefaultValues,
  oBlockChecker,
}: any) {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <ControlledBox
        title="Notification"
        description="Please add information of the people who you would like to receive notifications through
          text"
        info={
          <Typography color={"white"} variant="body2" padding={"10px"}>
            Dealers can customize notifications to fit their needs. They can set up two separate
            notification profiles with different names and phone numbers, and decide which events
            trigger notifications for each profile. For example, a dealer might route appointment
            and hot lead notifications to one employee&apos;s phone, while sending call requests and
            assistant requests to another employee&apos;s phone. They can also disable notifications
            for specific events, such as lead creation.
          </Typography>
        }
      >
        {state?.length > 0 &&
          state
            ?.filter((item: any) => !item.willBeRemoved)
            .map((notif: any, index: number) => (
              <AddNotification
                // key={s.firstName + s.lastName + s.id}
                key={notif.localId}
                index={index}
                notification={notif}
                localId={notif.localId}
                state={state}
                setState={setState}
                notificationDefaultValues={notificationDefaultValues}
                oBlockChecker={oBlockChecker}
                removeNotification={removeNotification}
                showRemoveButton={
                  state?.length > 0 && state?.filter((item: any) => !item.willBeRemoved).length > 1
                }
              />
            ))}

        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", marginBottom: "10px" }}>
          {state?.filter((item: any) => !item.willBeRemoved).length < 2 && (
            <Box display={"flex"}>
              <Button variant="outlined" startIcon={<AddIcon />} onClick={onMorePerson}>
                One More Person
              </Button>
            </Box>
          )}
          {/* {state?.filter((item: any) => !item.willBeRemoved).length > 0 && (
            <Box display={"flex"}>
              <Button variant="outlined" onClick={removeNotification}>
                Ignore
              </Button>
            </Box>
          )} */}
        </Box>

        {state?.filter((item: any) => !item.willBeRemoved).length > 1 && (
          <Box display="flex" flexDirection="column" gap="20px">
            <Alert
              variant="filled"
              severity="info"
              sx={{
                bgcolor: "#CDEDFE",
                color: "#027EC3",
                borderRadius: "8px",
              }}
            >
              To add one more notification number please
              <b style={{ color: "#027EC3" }}> contact support (888) 288-8856.</b>
            </Alert>
          </Box>
        )}
      </ControlledBox>
    </Box>
  );
}
