import { Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { getUid } from "../../redux/authSlice";
import { Button } from "../../components";
import Notifications from "./notification";
import { getData } from "../../utils/firebase";
import DealershipInformation from "./dealership-information";
import Snackbar from "../../components/snackbar/snackbar";
import { validateMobileNumber } from "../../utils/utils.service";
import { dealerNameSet } from "../../redux/userSlice";

import UseBlockerHandler from "../../components/useBlocker";
import SkeletonDealershipSetting from "../../components/skeletons/dealership-setting";
import { firebaseAuth } from "../../firebase/app";

const initialData = {
  firstName: "",
  lastName: "",
  phoneNumber: "+1",
  role: "owner",
  callbackRequest: false,
  appointments: false,
  hotLeads: false,
  firstResponder: false,
  dealerAssistant: false,
  willBeRemoved: false,
  newLeads: false,
};
export default function Dealership() {
  const dispatch = useDispatch();
  const [dealerShip, setDealership]: any = useState();
  const [notifications, setNotifications]: any = useState([]);
  const uid = useSelector(getUid);
  const [notify, setNotify] = useState({ message: "", open: false, type: "" });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [dealerShipDefaultValues, setDealerShipDefaultValues] = useState({});
  const [notificationDefaultValues, setNotificationDefaultValues] = useState({});
  const [blockChecker, setBlockChecker] = useState(false);

  const handleGetData = (data: any) => {
    console.log(data);
    const { dealerName, dealerEmail, dealerPhone, dealerAddress } = data;
    setDealership({
      dealerName,
      dealerEmail,
      dealerPhone: dealerPhone.startsWith("+1")
        ? dealerPhone
        : "+1" + dealerPhone.replace(/\D/g, ""),
      dealerAddress,
    });

    setDealerShipDefaultValues({ dealerName, dealerEmail, dealerPhone, dealerAddress });
  };

  const handleGetNotifications = (data: any) => {
    if (!data) return;
    // Collect notifications from object keys
    const notificationsToAdd = Object.keys(data).map((key) => ({
      localId: key,
      id: key,
      ...data[key],
    }));
    setNotifications(() => {
      setNotificationDefaultValues(notificationsToAdd);
      return [...notificationsToAdd];
    });
  };

  const checkNotificationStatus = () => {
    const shouldUpdates: [] = notifications
      .filter((notification: { id: any }) => notification?.id)
      .map((item: any) => (item.willBeRemoved ? { id: item?.id } : item));
    const shouldCreates: [] = notifications.filter(
      (notification: any) => !notification?.id && !notification.willBeRemoved
    );

    return { shouldUpdates, shouldCreates };
  };

  const getAllData = useCallback(() => {
    getData(`dealers/${uid}/info`).then((r: any) => handleGetData(r));
    getData(`dealers/${uid}/info/notification`).then((r: any) => handleGetNotifications(r));
    setBlockChecker(false);
  }, [uid]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const postDealershipInfo = async () => {
    const user: any = firebaseAuth.currentUser;
    const token = await user.getIdToken();
    const apiClient = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}/salesAgent-settingsApi`,
      headers: {
        Authorization: ` ${token}`,
        "Content-Type": "application/json",
      },
    });
    const { shouldUpdates, shouldCreates } = checkNotificationStatus();

    const phone = dealerShip.dealerPhone.startsWith("+1")
      ? dealerShip.dealerPhone.slice(2)
      : dealerShip.dealerPhone;

    const data = {
      address: dealerShip.dealerAddress,
      email: dealerShip.dealerEmail,
      phoneNumber: `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`,
      name: dealerShip.dealerName,
    };

    const dealerShipValue = [
      {
        section: "user_info",
        data,
      },
    ];

    const updateValue = [
      {
        section: "notification_data",
        data: shouldUpdates,
      },
    ];

    let postValue = {};

    if (shouldCreates.length > 0)
      postValue = [
        {
          section: "notification_data",
          data: { notifs: shouldCreates },
        },
      ];

    try {
      await apiClient.put("", dealerShipValue);
      if (shouldUpdates.length > 0) {
        await apiClient.put("", updateValue);
      }
      if (shouldCreates.length > 0) {
        await apiClient.post("", postValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMorePerson = () => {
    setNotifications((notifs: any) => [
      ...notifs,
      { ...initialData, localId: String(Math.floor(Math.random() * 100)) },
    ]);
  };

  const handleRemoveNotification = (id: any) => {
    if (id.length < 3) {
      setNotifications((notifs: any) => notifs.filter((notif: any) => notif.localId !== id));
    } else {
      setNotifications((notifs: any) =>
        notifs.map((notif: any) => (notif.id === id ? { ...notif, willBeRemoved: true } : notif))
      );
    }
  };

  const postData = () => {
    if (!dealerShip.dealerName || !dealerShip.dealerPhone || !dealerShip.dealerAddress) return;
    setSubmitLoading(true);
    postDealershipInfo()
      .then(() => {
        setNotify({ message: "success", open: true, type: "" });
        dispatch(dealerNameSet(dealerShip.dealerName));
      })
      .catch((err) => {
        setNotify({ message: err.message || "error", open: true, type: "error" });
      })
      .finally(() => {
        setSubmitLoading(false);
        getAllData();
      });
  };

  if (!dealerShip) return <SkeletonDealershipSetting />;
  return (
    <>
      {dealerShip && (
        <DealershipInformation
          state={dealerShip}
          setState={setDealership}
          dealerShipDefaultValues={dealerShipDefaultValues}
          oBlockChecker={setBlockChecker}
        />
      )}
      {notifications && dealerShip && (
        <Notifications
          state={notifications}
          setState={setNotifications}
          onMorePerson={handleMorePerson}
          removeNotification={handleRemoveNotification}
          notificationDefaultValues={notificationDefaultValues}
          oBlockChecker={setBlockChecker}
        />
      )}
      {dealerShip && (
        <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1} mb={1}>
          <Button onClick={() => getAllData()} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={postData}
            disabled={
              dealerShip &&
              (!dealerShip.dealerName ||
                !dealerShip.dealerPhone ||
                // !validateMobileNumber(dealerShip.dealerPhone) ||
                // // !dealerShip.email ||
                // // !validateEmail(dealerShip.email) ||
                !dealerShip.dealerAddress ||
                notifications
                  .filter((item: any) => !item.willBeRemoved)
                  .find((item: any) => !item.firstName || !validateMobileNumber(item.phoneNumber)))
                ? true
                : false
            }
          >
            {submitLoading ? <CircularProgress color="info" size={16} /> : "Save Change"}
          </Button>
        </Box>
      )}
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
      <UseBlockerHandler status={blockChecker} />
    </>
  );
}
