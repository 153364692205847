import React from "react";
import Menu from "./menu/menu";
import { IconButton, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMode, modeSet } from "../redux/layoutSlice";
import { ref, update } from "firebase/database";
import { firebaseDb } from "../firebase/app";
import { getIsBuyAgent, getUid } from "../redux/authSlice";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import { useNavigate } from "react-router";
import LogoutIcon from "@mui/icons-material/Logout";
import LogOut from "../sections/auth/logout";
import InventoryIcon from "../assets/icon/inventory";

export default function NavMenu({ anchorEl, handleClose, isMobile }: any) {
  const dispatch = useDispatch();
  const mode = useSelector(getMode);
  const uid = useSelector(getUid);
  const isBuyAgent = useSelector(getIsBuyAgent);
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = React.useState(false);

  const handleLogout = () => {
    handleClose();
    setOpenLogout(!openLogout);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} handleClose={handleClose}>
        <MenuItem
          onClick={() => {
            dispatch(modeSet(mode === "dark" ? "light" : "dark"));
            localStorage.setItem("colorMode", mode === "dark" ? "light" : "dark");
            handleClose();
            const data = {
              theme: mode === "dark" ? "light" : "dark",
            };
            const userPreferences = {
              darkMode: mode === "dark" ? false : true,
            };
            localStorage.setItem("userPreferences", JSON.stringify(userPreferences));
            update(ref(firebaseDb, `dealers/${uid}/info/dashboardSettings`), data);
          }}
        >
          <IconButton sx={{ color: "black" }}>
            {mode === "dark" ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
          </IconButton>
          <Typography fontWeight={500}>{mode === "dark" ? "Light" : "Dark"}</Typography>
        </MenuItem>
        {
          !isBuyAgent && isMobile && (
            <MenuItem
              onClick={() => {
                navigate("/cars");
                handleClose();
              }}
            >
              <IconButton sx={{ color: "black" }}>
                <InventoryIcon />
              </IconButton>
              <Typography fontWeight={500}>Cartelligent</Typography>
            </MenuItem>
          )
          // <MenuItem
          //   onClick={() => {
          //     navigate("/setting/dealership");
          //     handleClose();
          //   }}
          // >
          //   <IconButton sx={{ color: "black" }}>
          //     <SettingsRoundedIcon />
          //   </IconButton>
          //   <Typography fontWeight={500}>Setting</Typography>
          // </MenuItem>
        }
        <MenuItem onClick={handleLogout}>
          <IconButton sx={{ color: "black" }}>
            <LogoutIcon />
          </IconButton>
          <Typography fontWeight={500}>Log Out</Typography>
        </MenuItem>
      </Menu>
      <LogOut open={openLogout} handleClose={handleLogout} />
    </>
  );
}
