import * as yup from "yup";
import { EMAIL_REGEX } from "../utils/utils.service";

export const DealershipInformationSchema = yup.object().shape({
  name: yup.string().required("Please enter dealership name"),
  phoneNumber: yup.string().required("Please enter your phone number").min(12),
  email: yup
    .string()
    .email("Please enter a valid email")
    .matches(EMAIL_REGEX, "Please enter a valid email"),
  address: yup.string().required("Please enter your address"),
});
