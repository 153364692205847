import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { firebaseAuth } from "../firebase/app";

const initialState = {
  name: "",
  uid: null,
  token: "",
  buyAgent: false,
};

export const handleToken = createAsyncThunk("auth/handleToken", async () => {
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      // Get the Auth token
      return await user.getIdToken();
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    authSet: (state, { payload }) => {
      if (payload === undefined) {
        state.name = "undefined";
        state.uid = null;
        state.token = "";
        state.buyAgent = false;
      } else {
        const { displayName, uid, token, buyAgent } = payload;
        state.name = displayName;
        state.uid = uid;
        state.token = token;
        state.buyAgent = buyAgent;
      }
    },
    tokenSet: (state, { payload }) => {
      state.token = payload;
    },
    logout: (state) => {
      state.token = "";
      state.uid = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleToken.fulfilled, (state: any, { payload }: any) => {
      state.token = payload;
    });
    builder.addCase(handleToken.rejected, () => {
      console.log("@handleToken error");
    });
    builder.addCase(handleToken.pending, () => {
      console.log();
    });
  },
});

export const getToken = createSelector(
  (state: any) => state.auth,
  (auth: any) => auth.token
);

export const getUid = createSelector(
  (state: any) => state.auth,
  (auth: any) => auth.uid
);

export const getIsBuyAgent = createSelector(
  (state: any) => state.auth,
  (auth: any) => auth.buyAgent
);

export default authSlice.reducer;
export const { authSet, logout } = authSlice.actions;
