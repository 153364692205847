import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import ControlledInput from "../../components/input/controlled-input";
import { Controller, useForm } from "react-hook-form";
import ControlledBox from "../../components/box/controlled-box";
import MuiPhone from "../../components/input/mui-phone-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { DealershipInformationSchema } from "../../schemas/dealership-information.schema";
import { areObjectsEqual } from "../../utils/utils.service";
import { useResponsive } from "../../hooks/use-media-query";

interface FormInputs {
  name: string;
  phoneNumber: string;
  email?: string;
  address: string;
}

const DealershipInformation = ({
  state,
  setState,
  dealerShipDefaultValues,
  oBlockChecker,
}: any) => {
  const { isSmall } = useResponsive();

  const {
    control,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    defaultValues: useMemo(() => {
      return {
        name: state?.dealerName,
        phoneNumber: state?.dealerPhone,
        email: state?.dealerEmail,
        address: state?.dealerAddress,
      };
    }, [state?.dealerAddress, state?.dealerEmail, state?.dealerName, state?.dealerPhone]),
    mode: "onChange",
    resolver: yupResolver(DealershipInformationSchema),
  });

  useEffect(() => {
    const subscription = watch(({ name, phoneNumber: phone_number, email, address }: any) => {
      // Update state based on watched values

      setState((prevState: any) => {
        const newState = {
          ...prevState,
          dealerName: name,
          dealerPhone: phone_number,
          dealerEmail: email,
          dealerAddress: address,
        };

        const isEqual = areObjectsEqual(dealerShipDefaultValues, newState);
        oBlockChecker(!isEqual);
        return newState; // Return the new state
      });
    });
    return () => subscription.unsubscribe();
  }, [dealerShipDefaultValues, oBlockChecker, setState, state, watch]);

  return (
    <Box gap={1} display={"flex"} flexDirection={"column"}>
      <Box display={"flex"}>
        <ControlledBox title="Dealership Information">
          <Box display={"flex"} flexDirection={isSmall ? "column" : "row"} gap={2}>
            <Box width="100%">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <ControlledInput
                    error={errors.name && errors.name.message}
                    id="fullName"
                    label="Dealership Name *"
                    placeholder="Enter dealership name"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Box>

            <Box width="100%">
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <MuiPhone
                    label={"Phone Number *"}
                    id="phoneNumber"
                    placeholder="Enter your phone number"
                    error={errors.phoneNumber && errors.phoneNumber.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box width="100%">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <ControlledInput
                    error={field.value && errors.email && errors.email.message}
                    id="email"
                    label="Dealership Email"
                    placeholder="Enter your email"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
          <Box width="100%" marginTop="10px">
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <ControlledInput
                  error={errors.address && errors.address.message}
                  id="address"
                  label="Dealership Address *"
                  placeholder="Enter your address"
                  type="text"
                  {...field}
                />
              )}
            />
          </Box>
        </ControlledBox>
      </Box>
    </Box>
  );
};

export default DealershipInformation;
