import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { authSet, getUid } from "../redux/authSlice";
import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseApp } from "../firebase/app";
import DashboardLayout from "../layout/dashboard-layout";
import Login from "../pages/login";
import SkeletonLayout from "../components/skeletons/layout";
import { getData } from "./firebase";

const PrivateRoute = () => {
  const uid = useSelector(getUid);
  const auth = uid;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        const { uid, displayName, accessToken } = user;
        getData(`/dealers/${uid}/info/buyAgent`).then((data) => {
          const userData = {
            displayName,
            uid,
            token: accessToken,
            buyAgent: !!data,
          };
          dispatch(authSet(userData));
          setLoading(false);
        });
      } else {
        console.log("unauthorize");
        setLoading(false);
        dispatch(authSet(undefined));
        navigate("/login");
      }
    });
  }, [dispatch, navigate]);

  // useEffect(() => {
  //   if (!auth) {
  //     navigate("/login");
  //   }
  // }, [auth, navigate]);

  // if (loading) return <PageLoading fullScreen />;
  if (loading) return <SkeletonLayout />;

  if (!auth) return <Login />;
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
    // <PageLoading />
    // <SkeletonLayout />
  );
};

export default PrivateRoute;
