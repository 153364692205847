import React, { SetStateAction, Dispatch } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { generateClock } from "../../utils/utils.service";
import Selector from "../../components/select/select";
import EditableSelect from "./editable-select";
import { useSelector } from "react-redux";
import { getMode } from "../../redux/layoutSlice";

type SetMorningType = Dispatch<SetStateAction<string>> | ((value: string) => void);
type SetHourType = Dispatch<SetStateAction<string>> | ((value: string) => void);

export default function TimeSelect({
  hour,
  setHour,
  morning,
  setMorning,
  disabled,
}: {
  hour: string;
  setHour: SetHourType;
  morning: string;
  setMorning: SetMorningType;
  disabled?: boolean;
}) {
  const mode = useSelector(getMode);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#d9d9d9",
        padding: "1px",
        borderRadius: "20px",
      }}
    >
      <EditableSelect hour={hour} setHour={setHour} options={generateClock()} disabled={disabled} />
      <Box
        sx={{
          padding: "10px 0",
          height: "41px",
          // borderTop: "1px solid #E5E5E5",
          // borderBottom: "1px solid #E5E5E5",

          backgroundColor: (theme: any) =>
            disabled ? (mode === "dark" ? "#232323" : "#DADADA") : theme.bg.paper,
        }}
      >
        <Box sx={{ backgroundColor: "#E5E5E5", width: "2px", height: "100%" }}></Box>
      </Box>
      <Selector
        disabled={disabled}
        data={["am", "pm"]}
        width={85}
        height={41}
        // minHeight={85}
        sx={{
          // borderRadius: "20px",
          borderRadius: "0 20px 20px 0",
          border: "none !important",
          minWidth: "85px",
          backgroundColor: (theme: any) =>
            disabled ? (mode === "dark" ? "#232323" : "#DADADA") : theme.bg.paper,
          "&>.MuiOutlinedInput-notchedOutline": {
            border: "none !important",
            borderColor: "#E5E5E5",
            // "&>:hover": { borderColor: "#E5E5E5" },
            // borderLeft: "1px solid rgba(212, 212, 212, 0.58) !important",
          },

          "&>.MuiOutlinedInput-input": { minHeight: "24px !important" },
        }}
        value={morning}
        onChange={(e: SelectChangeEvent) => setMorning(e.target.value as string)}
      />
    </Box>
  );
}
