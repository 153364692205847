import React, { ReactNode } from "react";

import { Box, Box as MUBox, Typography, BoxProps, Tooltip, IconButton } from "@mui/material";
import QuestionMarkIcon from "../../assets/icon/question-mark";

export default function ControlledBox({
  children,
  title,
  description,
  info,
  ...props
}: {
  children: ReactNode;
  title: string;
  description?: string;
  info?: string | ReactNode;
} & BoxProps) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
      {title && (
        <Box display={"block"}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: "700" }}>
              {title}
            </Typography>
            {info && (
              <Tooltip title={info}>
                <IconButton>
                  <QuestionMarkIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {description && (
            <Typography color={"#9B9B9B"} variant="body1" sx={{ fontSize: "16px" }}>
              {description}
            </Typography>
          )}
        </Box>
      )}
      <MUBox
        bgcolor={(theme) => theme.bg.paper}
        py={2}
        px={2}
        marginBottom={"10px"}
        borderRadius={4}
        width={"100%"}
        sx={{ display: "flex", flexDirection: "column" }}
        {...props}
      >
        {children}
      </MUBox>
    </Box>
  );
}
