import * as React from "react";
import { BottomNavigation as MuiBottomNavigation } from "@mui/material";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ReportIcon from "../assets/icon/report";
import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import { bottomHeight } from "../config/constant";
import NavMenu from "../components/nav-menu";
import PersonIcon from "@mui/icons-material/Person";
import { useSelector } from "react-redux";
import { getIsBuyAgent } from "../redux/authSlice";

export default function BottomNavigation() {
  const [value, setValue] = React.useState("/");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isBuyAgent = useSelector(getIsBuyAgent);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (pathname.startsWith("/-")) {
      setValue("/");
    } else {
      setValue(pathname);
    }
  }, [pathname, anchorEl]);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1, height: bottomHeight }}
      elevation={3}
    >
      <MuiBottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Inbox"
          value="/"
          icon={<ChatRoundedIcon />}
          onClick={() => navigate("/")}
        />
        <BottomNavigationAction
          label="Appointment"
          value="/appointment"
          icon={<CalendarMonthRoundedIcon />}
          onClick={() => navigate("/appointment")}
        />
        {!isBuyAgent && (
          <BottomNavigationAction
            label="Report"
            value="/report"
            icon={<ReportIcon />}
            onClick={() => navigate("/report")}
          />
        )}
        <BottomNavigationAction
          onClick={handleOpenUserMenu}
          label="Profile"
          value="/profile"
          icon={<PersonIcon />}
        />
      </MuiBottomNavigation>
      <NavMenu isMobile={true} anchorEl={anchorEl} handleClose={handleCloseMenu} />
    </Paper>
  );
}
