import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CustomizedSwitches from "../../components/switch/customize-switch";
import {
  areObjectsEqual,
  convertTo24Hour,
  handleChangeFormat,
  transformArrays,
} from "../../utils/utils.service";
import { Button } from "../../components";
import ControlledBox from "../../components/box/controlled-box";
import axios from "axios";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import { getData } from "../../utils/firebase";
import Snackbar from "../../components/snackbar/snackbar";
import TimeSelect from "../../components/utils/time-select";
import UseBlockerHandler from "../../components/useBlocker";
import { firebaseAuth } from "../../firebase/app";

const initialValue = [
  {
    day: "Monday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointmentOnly: false,
  },
  {
    day: "Tuesday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointmentOnly: false,
  },
  {
    day: "Wednesday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointmentOnly: false,
  },
  {
    day: "Thursday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointmentOnly: false,
  },
  {
    day: "Friday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointmentOnly: false,
  },
  {
    day: "Saturday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointmentOnly: false,
  },
  {
    day: "Sunday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointmentOnly: false,
  },
];

const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export default function BusinessHours() {
  const [week, setWeek]: any = React.useState(initialValue);
  const [defaultWeek, setDefaultWeek]: any = React.useState(initialValue);

  const [isUpdate, setIsUpdate]: any = React.useState(false);

  const uid = useSelector(getUid);
  const [notify, setNotify] = useState({ message: "", open: false, type: "" });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [blockChecker, setBlockChecker] = useState(false);

  const handleGetData = (data: any) => {
    if (!data) return;
    const { appointmentOnly, workingHours = "" } = data;
    const updateDate = transformArrays(workingHours, appointmentOnly).sort(
      (a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day)
    );
    setWeek(updateDate);
    setDefaultWeek(updateDate);
    setIsUpdate(true);
  };

  const getAllData = useCallback(() => {
    getData(`dealers/${uid}/info`).then((r: any) => {
      handleGetData(r);
    });
  }, [uid]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const postData = async () => {
    const user: any = firebaseAuth.currentUser;
    const token = await user.getIdToken();
    setSubmitLoading(true);

    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}/salesAgent-settingsApi`,
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });
    const value = [
      {
        section: "time_operation",
        data: {
          days: handleChangeFormat(week),
        },
      },
    ];

    try {
      if (isUpdate) {
        const { data } = await apiClient.put("", value);
        data.forEach((element: { status_code: number; response: any }) => {
          if (element.status_code === 200) setNotify({ message: "Success", open: true, type: "" });
          else setNotify({ message: "Smt went wrong.", open: true, type: "error" });
        });
      } else await apiClient.post("", value);
    } catch (error: any) {
      setNotify({ message: error.response?.data?.error, open: true, type: "error" });
    } finally {
      getAllData();
      setSubmitLoading(false);
    }
  };

  const handleChangeDay = (day: string, updates: object) => {
    setWeek((prevWeek: any) =>
      prevWeek.map((item: any) => (item.day === day ? { ...item, ...updates } : item))
    );
  };

  const handleChange = (status: string, day: string) => {
    setWeek((prevWeek: any) =>
      prevWeek.map((item: any) =>
        item.day === day
          ? status === "closed"
            ? {
                ...item,
                closed: !item.closed,
                appointmentOnly: !item.closed ? false : item.appointmentOnly,
                start: !item.closed ? item.start : "8:00",
                end: !item.closed ? item.end : "5:00",
                midnight: !item.closed ? item.midnight : "pm",
              }
            : {
                ...item,
                appointmentOnly: !item.appointmentOnly,
                closed: item.appointmentOnly ? false : item.close,
                start: item.closed ? "8:00" : item.start,
                end: item.closed ? "5:00" : item.end,
                midnight: item.closed ? "pm" : item.midnight,
              }
          : item
      )
    );
  };

  useEffect(() => {
    const isEqual = areObjectsEqual(week, defaultWeek);
    setBlockChecker(!isEqual);
  }, [defaultWeek, week]);

  return (
    <>
      <ControlledBox title="Hours of Operations">
        {week?.map((w: any, id: any) => (
          <Box
            key={id}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              width={113}
              height={80}
              border={"1px solid #E3E3E3"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={1}
              p={1}
              my={1}
              sx={{ backgroundColor: w.closed && "#E3E3E3", borderRadius: "10px" }}
            >
              <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                {w.day}
              </Typography>
              {w.closed ? (
                <Typography
                  sx={{
                    backgroundColor: "white",
                    textAlign: "center",
                    borderRadius: "20px",
                    color: "#7D7F80",
                    fontSize: "13px",
                  }}
                >
                  closed
                </Typography>
              ) : (
                <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                  {convertTo24Hour(w?.start, w?.morning)} - {convertTo24Hour(w?.end, w?.midnight)}
                </Typography>
              )}
            </Box>
            <Box ml={1}>
              <Typography>From</Typography>
              <Box display={"flex"} gap={1}>
                <TimeSelect
                  disabled={w.closed}
                  hour={w.start}
                  setHour={(value: string) =>
                    handleChangeDay(w.day, {
                      start: value,
                      morning: w.morning,
                      end: w.end,
                      midnight: w.midnight,
                    })
                  }
                  morning={w.morning}
                  setMorning={(value: string) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: value,
                      end: w.end,
                      midnight: w.midnight,
                    })
                  }
                />
              </Box>
            </Box>
            <Box ml={2}>
              <Typography>To</Typography>
              <Box display={"flex"} gap={1}>
                <TimeSelect
                  disabled={w.closed}
                  hour={w.end}
                  setHour={(value: string) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: w.morning,
                      end: value,
                      midnight: w.midnight,
                    })
                  }
                  morning={w.midnight}
                  setMorning={(value: string) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: w.midnight,
                      end: w.end,
                      midnight: value,
                    })
                  }
                />
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mx={1} alignItems={"center"}>
              <Box mr={1}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Appointment Only
                </Typography>
              </Box>
              <CustomizedSwitches
                name="appointmentOnly"
                checked={w.appointmentOnly}
                onChange={() => handleChange("appointmentOnly", w.day)}
              />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mx={1} alignItems={"center"}>
              <Box mr={1}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Closed
                </Typography>
              </Box>
              <CustomizedSwitches
                onChange={() => handleChange("closed", w.day)}
                checked={w.closed}
                name="closed"
              />
            </Box>
          </Box>
        ))}
      </ControlledBox>
      <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
        <Button onClick={getAllData} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={postData}>
          {submitLoading ? <CircularProgress color="info" size={16} /> : "Save Change"}
        </Button>
      </Box>
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
      <UseBlockerHandler status={blockChecker} />
    </>
  );
}
