import React from "react";
import Button from "./button";

export default function AnimationButton({ title, ...props }: any) {
  return (
    <Button
      variant={"contained"}
      sx={{
        fontWeight: 700,
        boxShadow: "none",
        padding: ".75rem 2rem",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "transparent",
        color: "#fff",
        "&::after": {
          content: '""',
          position: "absolute",
          backgroundImage: "linear-gradient(to left, #83068A 10%, #008EFF 100% , #430EAB 30%)",
          top: "-8rem",
          left: "-1rem",
          borderRadius: "100%",
          padding: "10rem 10rem",
          zIndex: "-2",
          animation: "spin 3s linear infinite",
          "@keyframes spin": {
            "100%": {
              transform: "rotate(320deg)",
            },
          },
        },
      }}
      {...props}
    >
      {title}
    </Button>
  );
}
