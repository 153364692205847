import { Alert, Snackbar as MuiSnackbar, useTheme } from "@mui/material";
import * as React from "react";

export default function Snackbar(props: any) {
  const { handleClose, open, message, type, mobile } = props;
  const theme = useTheme();

  const backgroundColor = () => {
    if (type === "error") return "#E00F0F";
    else return theme.palette.success.dark;
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      autoHideDuration={3000}
      onClose={handleClose}
      open={open}
      sx={{
        bottom: mobile && "10%",
        position: mobile && "fixed",
        alignItems: mobile && "baseline",
      }}
    >
      <Alert
        icon={false}
        onClose={handleClose}
        sx={{
          backgroundColor: backgroundColor(),
          color: "white",
          width: "100%",
        }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
