import { Box, Skeleton } from "@mui/material";
import React from "react";
import { useResponsive } from "../../hooks/use-media-query";

export default function SkeletonLayout() {
  const { isCustomLayout } = useResponsive();

  return (
    <Box
      sx={{ width: "100%", height: "100dvh", backgroundColor: (theme: any) => theme.skeleton.bg }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "65px",
          gap: "8px",
          alignItems: "center",
          paddingLeft: "32px",
          backgroundColor: (theme: any) => theme.bg.paper,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "65px",
            gap: "8px",
            alignItems: "center",
            backgroundColor: (theme: any) => theme.bg.paper,
          }}
        >
          {!isCustomLayout ? (
            [...Array(5)].map((_, index) => (
              <SkeletonItem
                width={index === 0 ? "120px" : index === 4 ? "180px" : "140px"}
                shape={"rectangular"}
                rounded={index === 4}
                key={index}
              />
            ))
          ) : (
            <SkeletonItem shape={"rectangular"} />
          )}{" "}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "65px",
            gap: "8px",
            alignItems: "center",
            backgroundColor: (theme: any) => theme.bg.paper,
            paddingRight: "32px",
          }}
        >
          {!isCustomLayout &&
            [...Array(2)].map((_, index) => (
              <SkeletonItem
                width={index === 0 ? "240px" : "160px"}
                rounded
                shape={"rectangular"}
                key={index}
              />
            ))}
        </Box>
      </Box>
      {!isCustomLayout && (
        <Box
          sx={{
            backgroundColor: (theme: any) => theme.bg.paper,
            height: "calc(100dvh - 65px)",
            width: "72px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme: any) => theme.bg.paper,
              width: "72px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {[...Array(6)].map((_, index) => (
              <SkeletonItem shape={"square"} key={index} />
            ))}{" "}
          </Box>
          <Box
            sx={{
              backgroundColor: (theme: any) => theme.bg.paper,
              width: "72px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {[...Array(3)].map((_, index) => (
              <SkeletonItem shape={"square"} key={index} />
            ))}{" "}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export const SkeletonItem = ({
  shape,
  rounded,
  width,
}: {
  shape?: "rectangular" | "square";
  rounded?: boolean;
  width?: string;
}) => {
  return (
    <Skeleton
      variant="rectangular"
      height={shape === "square" ? "64px" : "48px"}
      width={width ? width : shape === "square" ? "64px" : "160px"}
      sx={{
        borderRadius: rounded === true ? "32px" : "8px",
        bgcolor: (theme: any) => theme.skeleton.bg,
      }}
    ></Skeleton>
  );
};
