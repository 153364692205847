import React, { useEffect, useRef } from "react";
import { Box, MenuItem } from "@mui/material";
import { useState } from "react";
import ControlledInput from "../input/controlled-input";
import ClockIcon from "../../assets/icon/clock";
import ArrowDownIcon from "../../assets/icon/arrow-down";
import { useSelector } from "react-redux";
import { getMode } from "../../redux/layoutSlice";

export default function EditableSelect({ setHour, hour, options, disabled }: any) {
  const [showMenu, setShowMenu] = useState(false);

  const mode = useSelector(getMode);

  const modalRef = useRef<any>(null);

  const handleToggle = () => {
    if (disabled) return;
    setShowMenu((showMenu) => !showMenu);
  };

  const handleChange = (e: any) => {
    const value = e.target.value;

    const hour = value.split(":")[0];
    const minute = value.split(":")[1];

    if (
      value === "" ||
      ((hour === "" || /^([0-9]|1[0-2])$/.test(hour)) &&
        (minute === "" || /^([0-5]?[0-9])$/.test(minute)))
    ) {
      setHour(value);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <ControlledInput
        value={hour}
        disabled={disabled}
        onChange={handleChange}
        sx={{
          width: "135px",
          height: "41px",
          fontSize: "16px",
          fontWeight: "500",
          backgroundColor: (theme: any) =>
            disabled ? (mode === "dark" ? "#232323" : "#DADADA") : theme.bg.paper,
          ":hover": {
            border: "1px solid black",
          },
          ":focus": {
            border: "1px solid #07A4FC !important",
          },
          border: "none !important",
          borderRadius: "20px 0 0 20px",
        }}
        startAdornment={
          <ClockIcon
            sx={{
              cursor: disabled ? "normal" : "pointer",
              color: disabled ? "#E3E3E3" : "gray",
              marginRight: "10px",
            }}
            onClick={handleToggle}
          />
        }
        endAdornment={
          <ArrowDownIcon
            sx={{ cursor: disabled ? "normal" : "pointer", color: "gray" }}
            onClick={handleToggle}
          />
        }
      />
      {showMenu && (
        <Box
          ref={modalRef}
          sx={{
            position: "absolute",
            zIndex: "99990",
            backgroundColor: (theme: any) => theme.bg.paper,
            bottom: "0",
            right: "0",
            height: "300px",
            overflow: "auto",
            boxShadow: (theme: any) => `1px 1px 20px 1px  ${theme.bg.shadow}`,
            borderRadius: "5px",
          }}
        >
          {options?.map((i: any, index: number) => (
            <MenuItem
              onClick={() => {
                setHour(i);
                setShowMenu(false);
              }}
              key={index}
              value={i}
            >
              {i}
            </MenuItem>
          ))}
        </Box>
      )}
    </Box>
  );
}
