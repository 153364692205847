import React from "react";
import Button from "./button";
import Animation from "../../components/animation";
import animationData from "../../assets/lottie/voice-transparent.json";
import { useSelector } from "react-redux";
import { getMode } from "../../redux/layoutSlice";

export default function OutlineAnimationButton({ title, ...props }: any) {
  const mode = useSelector(getMode);

  return (
    <Button
      variant="cover"
      color="primary"
      style={{
        width: "240px",
        height: "50px",
        padding: "1px",
        border: "double 1px transparent",
        borderRadius: "58px",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
      }}
      sx={{
        fontWeight: 700,
        boxShadow: "none",
        padding: ".75rem 2rem",
        overflow: "hidden",
        color: mode === "dark" ? "white" : "black",
        backgroundImage:
          mode === "dark"
            ? "linear-gradient(black, black), linear-gradient(to right, #2981ef, #e820d3)"
            : "linear-gradient(white, white), linear-gradient(to right, #2981ef, #e820d3)",
      }}
      {...props}
    >
      <Animation
        animationData={animationData}
        autoplay
        loop
        style={{
          height: 40,
          width: 40,
          marginRight: "5px",
        }}
      />
      {title}
    </Button>
  );
}
