import * as React from "react";
import Box from "@mui/material/Box";

export default function PageLoading({ fullScreen }: { fullScreen?: boolean }) {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: fullScreen ? "100dvh" : "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <img src="/images/infinity-logo.png" width={"400px"} height={"200px"} alt="loading" />
    </Box>
  );
}
