import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Grid, Stack, Typography, useTheme } from "@mui/material";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { To, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAuth, getIdTokenResult, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { firebaseApp, firebaseAuth } from "../firebase/app";
import Dialog from "../components/dialog/dialog";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { Button } from "../components";
import { EMAIL_REGEX } from "../utils/utils.service";
import ControlledInput from "../components/input/controlled-input";
import CustomSnackbar from "../components/snackbar/custom-snackbar";
import { useSelector } from "react-redux";
import { getUid } from "../redux/authSlice";

const schema = yup.object().shape({
  email: yup
    .string()
    .label("email")
    .required("Please enter your email")
    .email("Please enter a valid email")
    .matches(EMAIL_REGEX, "Please enter a valid email"),
  password: yup.string().label("password").required("password is required").min(3),
});

function ContactUs({ open, setOpen }: any) {
  const renderTitle = "Contact support";
  const renderContent = (
    <Typography align="center">
      Your account has been suspended, please contact support.
      <br /> (888) 288-8856.
    </Typography>
  );
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"}>
      <Button
        onClick={() => setOpen(false)}
        color="primary"
        sx={{
          color: "white",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: 400,
        }}
        variant="contained"
      >
        ok
      </Button>
    </Box>
  );

  return (
    <Dialog
      open={open}
      fullWidth
      handleClose={() => setOpen(false)}
      renderActions={renderActions}
      renderContent={renderContent}
      renderTitle={renderTitle}
    />
  );
}

const defaultValues = {
  email: "",
  password: "",
};

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({ message: "", open: false, type: "" });
  const [open, setOpen] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const uid = useSelector(getUid);

  const theme = useTheme();

  function handleNavigate(to: To) {
    navigate(to);
  }

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleRemoveAuth = () => {
    const auth = getAuth(firebaseApp);

    signOut(auth)
      .then(() => {
        setOpen(true);
        // Sign-out successful.
      })
      .catch((error) => console.log(error));
  };

  // check account access
  const getClaims = () => {
    const auth = getAuth(firebaseApp);
    const user = auth?.currentUser;
    if (!user) return;
    getIdTokenResult(user, true)
      .then((r: any) => {
        setLoading(false);
        // check exists salesAgent
        const product = r?.claims?.["salesAgent"];
        if (!product) handleRemoveAuth();
        if (product?.dashboard) handleNavigate("/");
        else handleRemoveAuth();
      })
      .catch((e) => console.log(e));
  };

  const onSubmit = () => {
    const { email, password } = getValues();
    setLoading(true);
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(() => {
        getClaims();
      })
      .catch(() => {
        setState({ message: "Incorrect email or password", open: true, type: "error" });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (uid) {
      navigate("/");
    }
  }, [navigate, uid]);

  return (
    <>
      <Grid
        container
        component="main"
        sx={{
          background: (theme) => theme.bg.paper,
          height: "100vh",
          overflow: "hidden",
          [theme.breakpoints.down("lg")]: {
            justifyContent: "center",
          },
        }}
      >
        <Grid item xs={12} sm={8} md={6} bgcolor={(theme) => theme.bg.paper}>
          <Stack
            height={"100%"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                [theme.breakpoints.down("lg")]: {
                  marginBottom: 4.5,
                },
                position: "fixed",
                left: "20px",
                top: "20px",
              }}
            >
              <img width={"120px"} alt="drivee logo" src="/images/00011.png" loading="lazy" />
            </Box>

            <Box
              sx={{
                width: "500px",
                marginBottom: 10,
                [theme.breakpoints.down("lg")]: {
                  marginBottom: 0,
                  width: "90%",
                },
              }}
            >
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "34px",
                  marginBottom: "12px",
                  fontWeight: 700,
                  lineHeight: "36px",
                  [theme.breakpoints.down("lg")]: {
                    fontSize: "24px",
                  },
                }}
              >
                Sign in to <span style={{ color: "#582F93" }}>A.I. Sales Agent</span>
              </Typography>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "28px",
                  [theme.breakpoints.down("lg")]: {
                    fontSize: "16px",
                  },
                }}
              >
                Discover a better way of using A.I. in dealership
              </Typography>

              <Box
                sx={{
                  marginTop: 5,
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                  },
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                          <ControlledInput
                            error={errors.email && errors.email.message}
                            id="email"
                            label="Email Address"
                            placeholder="Enter your email"
                            type="email"
                            {...field}
                          />
                        )}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        marginBottom: 4,
                      }}
                    >
                      <Controller
                        control={control}
                        name="password"
                        render={({ field }) => (
                          <ControlledInput
                            endAdornment={
                              <>
                                {!showPass ? (
                                  <VisibilityRoundedIcon
                                    sx={{ cursor: "pointer", color: "#8083A3" }}
                                    onClick={() => setShowPass((prev) => !prev)}
                                  />
                                ) : (
                                  <VisibilityOffRoundedIcon
                                    sx={{ cursor: "pointer", color: "#8083A3" }}
                                    onClick={() => setShowPass((prev) => !prev)}
                                  />
                                )}
                              </>
                            }
                            label="Password"
                            error={errors.password && errors.password.message}
                            id="password"
                            placeholder="Enter your password"
                            type={showPass ? "text" : "password"}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <CustomSnackbar
                    handleClose={() => setState({ message: "", open: false, type: "error" })}
                    message={state.message}
                    open={state.open}
                    type={state.type}
                  />

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      height: "45px",
                      "&:hover": {
                        // background: "#043676",
                      },
                      // backgroundColor: "#022676",
                      // color: "white !important",
                      fontSize: "16px",
                      fontWeight: 700,
                      // borderRadius: "0.6rem",
                      textTransform: "initial",
                      py: "8px",
                    }}
                    type="submit"
                  >
                    {loading ? <CircularProgress color="info" size={16} /> : "Sign In"}
                  </Button>
                  <Typography sx={{ marginTop: "16px", textAlign: "center" }}>
                    Don&apos;t have an account? <a href="https://drivee.ai/book-a-demo/">Sign up</a>{" "}
                  </Typography>
                </form>
              </Box>
            </Box>
            <Box></Box>
          </Stack>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundColor: "#07A4FC",
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "inherit",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="infinity logo"
                src="/images/infinitive.svg"
                style={{ width: "243px", height: "150px", opacity: "0.7", zIndex: 99 }}
                loading="lazy"
              />

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "36px",
                  textAlign: "center",
                  zIndex: 99,
                  position: "relative",
                  color: "white",
                  [theme.breakpoints.down("lg")]: {
                    fontSize: "20px",
                  },
                }}
              >
                Revolutionize your business <br /> with our cutting-edge A.I. solutions
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <svg
                style={{ position: "absolute", bottom: "0" }}
                xmlns="http://www.w3.org/2000/svg"
                width="706"
                height="469"
                viewBox="0 0 706 469"
                fill="none"
              >
                <path
                  opacity="0.8"
                  d="M181.006 464.552L453.186 395.441C515.586 379.597 569.388 340.125 603.232 285.359L689.665 145.494C713.273 107.293 711.135 58.5443 684.274 22.5567C669.36 2.57656 642.823 -4.78964 619.745 4.64523L358.703 111.366C353.116 113.649 347.371 115.523 341.512 116.971L48.0167 189.495C16.0811 197.386 -4.14486 228.83 1.91682 261.163L20.8725 362.271C34.7306 436.189 108.114 483.06 181.006 464.552Z"
                  fill="#F5F0FC"
                />
              </svg>

              <img src="/images/signin.png" alt="sign-in" style={{ width: "90%", zIndex: 9 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <ContactUs open={open} setOpen={() => setOpen(false)} />
    </>
  );
}
