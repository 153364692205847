import { createSelector, createSlice } from "@reduxjs/toolkit";

const userPreferences = localStorage.getItem("userPreferences");
const darkStatus = userPreferences && JSON.parse(userPreferences)?.darkMode;

const initialState = {
  mode: darkStatus ? "dark" : "light",
  checked: true,
};

const layoutSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    modeSet: (state, { payload }) => {
      state.mode = payload;
    },
    checkedSet: (state) => {
      state.checked = !state.checked;
    },
  },
});

export const getMode = createSelector(
  (state: any) => state.layout,
  (layout: any) => layout.mode
);

export default layoutSlice.reducer;
export const { modeSet, checkedSet } = layoutSlice.actions;
