import { CssBaseline, ThemeProvider } from "@mui/material";
import Layout from "./layout/layout";
import React from "react";
import { darkTheme, lightTheme } from "./config/theme";
import { useSelector } from "react-redux";
import { getMode } from "./redux/layoutSlice";

function App() {
  const mode = useSelector(getMode);

  return (
    <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
      <CssBaseline enableColorScheme />
      <Layout />
    </ThemeProvider>
  );
}

export default App;
