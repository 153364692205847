import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog as MuiDialog, Slide, Stack, Tooltip, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResponsive } from "../../hooks/use-media-query";
import QuestionMarkIcon from "../../assets/icon/question-mark";
import { useSelector } from "react-redux";
import { getMode } from "../../redux/layoutSlice";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  handleClose?: any;
  subTitle?: any;
  smallContent?: boolean;
  info?: any;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const {
    children,
    showCloseButton = true,
    handleClose,
    subTitle,
    smallContent,
    info,
    ...other
  } = props;

  return (
    <Stack
      sx={{
        alignItems: "center",
        display: "flex",
        fontSize: "28px !important",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: smallContent ? "0" : "32px",
      }}
      {...other}
    >
      <Typography variant="h6" fontSize={"28px"} fontWeight={700}>
        {children}
        {subTitle && <Typography>{subTitle}</Typography>}
      </Typography>
      <Box display={"flex"} alignItems={"center"}>
        {info && (
          <Tooltip title={info}>
            <IconButton>
              <QuestionMarkIcon />
            </IconButton>
          </Tooltip>
        )}
        {showCloseButton && (
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Stack>
  );
}

export interface DialogProps {
  open: boolean;
  handleClose: () => void;
  renderTitle?: React.ReactNode;
  renderContent?: React.ReactNode;
  renderActions?: React.ReactNode;
  onSubmit?: any;
  fullWidth?: boolean;
  maxWidth?: "xs|sm|md|lg|xl" | any;
  showCloseButton?: boolean;
  maxHeight?: any;
  display?: any;
  filter?: any;
  noPadding?: boolean;
  bgColor?: any;
  subTitle?: any;
  smallContent?: boolean;
  withoutActionSpace?: boolean;
  customWidth?: string;
  info?: any;
}

export default function Dialog(props: DialogProps) {
  const {
    open,
    handleClose,
    renderTitle,
    renderContent,
    renderActions,
    onSubmit,
    fullWidth = false,
    maxWidth = "sm",
    showCloseButton,
    noPadding,
    bgColor,
    subTitle,
    smallContent,
    withoutActionSpace,
    customWidth,
    info,
  } = props;

  const { isMobile } = useResponsive();
  const mode = useSelector(getMode);

  const BootstrapDialog = React.useMemo(() => {
    return styled(MuiDialog)(({ theme }) => ({
      "& .MuiDialog-paper": {
        padding: noPadding ? 0 : isMobile ? "16px" : "24px",
        borderRadius: 20,
      },
      "& .MuiDialogActions-root": {
        padding: noPadding ? 0 : theme.spacing(2, 1),
      },
      "& .MuiDialogContent-root": {
        minWidth: "100%",
        minHeight: 72,
        padding: 0,
        display: "flex",
        alignItems: "center",
      },
    }));
  }, [isMobile, noPadding]);

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      fullWidth={fullWidth}
      TransitionComponent={Transition}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
      sx={{
        // backdropFilter: "blur(7.5px)",
        // fill: "rgba(64, 64, 64, 0.35)",
        // fill: "red",
        padding: "0",
        maxWidth: "100%",
        backgroundColor: (theme: any) => theme.bg.backdrop,
        // mx: "auto",
        // display: "block",
        display: "flex",
        flexDirection: "column",
        gap: smallContent ? "0" : "32px",
        "& .MuiDialog-paper": {
          backgroundColor: bgColor ? bgColor : (theme: any) => theme.bg.paper,
          backgroundImage: "none !important",
          border: mode === "dark" ? "1px solid #414141" : "none",
          maxWidth: (theme: any) =>
            customWidth ? customWidth : isMobile ? "95%" : theme.modalBreakPoints[maxWidth],
          width: (theme: any) =>
            customWidth ? customWidth : isMobile ? "95%" : theme.modalBreakPoints[maxWidth],
          margin: isMobile ? "0" : "",
        },
        "& .MuiBackdrop-root-MuiDialog-backdrop": {
          // backgroundColor: (theme: any) => theme.bg.backdrop,
          // backgroundColor: "red !important",
          // backdropFilter: "blur(3.5px)",
          // margin: "0",
        },
      }}
    >
      {renderTitle && (
        <BootstrapDialogTitle
          handleClose={handleClose}
          id="customized-dialog-title"
          showCloseButton={showCloseButton}
          subTitle={subTitle}
          smallContent={smallContent}
          info={info}
        >
          {renderTitle}
        </BootstrapDialogTitle>
      )}
      <form
        onSubmit={onSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: smallContent || withoutActionSpace ? "0" : "32px",
        }}
      >
        <DialogContent
          sx={{
            height: "fit-content !important",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {renderContent}
        </DialogContent>
        {renderActions && (
          <DialogActions sx={{ padding: "0 !important" }}>{renderActions}</DialogActions>
        )}
      </form>
    </BootstrapDialog>
  );
}
