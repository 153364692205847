import { FormControl, Input as MuiInput, Typography } from "@mui/material";
import React, { memo, forwardRef } from "react";
import InputError from "../input-error";

// Wrap ControlledInput with forwardRef
const NewControlledInput = forwardRef(
  ({ error, fullWidth = true, label, sx, labelSize, iconImage, disabled, ...rest }: any, ref) => {
    return (
      <FormControl fullWidth={fullWidth}>
        {label && (
          <Typography
            fontWeight={500}
            fontSize={labelSize ? labelSize : "14px"}
            mb={1}
            variant="body1"
          >
            {label}
          </Typography>
        )}
        <MuiInput
          variant="outlined"
          inputRef={ref} // Pass the ref to the MuiInput
          sx={{
            // background: (theme) => (disabled ? "#EEE" : theme.bg.paper),
            background: (theme) => theme.bg.paper,
            borderRadius: "2rem",
            fontSize: "small",
            padding: (theme) => theme.spacing(1, 2),
            border: (theme: any) =>
              error ? "1px solid #E84933" : `1px solid ${theme.palette.secondary[200]}`,
            "&.Mui-focused": {
              border: (theme) =>
                error ? "1px solid #E84933" : `1px solid ${theme.palette.primary.main} !important`,
            },
            ...sx, // Merge the sx prop here
          }}
          disableUnderline
          startAdornment={
            iconImage ? (
              <img src={iconImage} width={20} height={20} style={{ marginRight: "10px" }} />
            ) : null
          }
          disabled={disabled}
          {...rest}
        />
        {error && <InputError message={error} />}
      </FormControl>
    );
  }
);

// Set the display name for better debugging
NewControlledInput.displayName = "NewControlledInput";

// Memoize the component for performance
export default memo(NewControlledInput);
