import React from "react";
import { Box } from "@mui/material";

import Skeleton from "@mui/material/Skeleton";

export default function SkeletonDealershipSetting() {
  return (
    <Box>
      <Skeleton
        variant="text"
        height={"45px"}
        width={"180px"}
        // sx={{ borderRadius: "8px" }}
      ></Skeleton>
      <Box
        sx={{
          backgroundColor: (theme: any) => theme.bg.paper,
          width: "100%",
          borderRadius: "12px",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          marginTop: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {[...Array(3)].map((_, index) => (
            <Box
              key={index}
              sx={{ display: "flex", flexDirection: "column", width: "32%", gap: "2px" }}
            >
              <Skeleton
                variant="text"
                height={"32px"}
                width={"180px"}
                // sx={{ borderRadius: "8px" }}
              ></Skeleton>
              <Skeleton
                variant="rectangular"
                height={"44px"}
                width={"100%"}
                sx={{ borderRadius: "32px" }}
              ></Skeleton>
            </Box>
          ))}
        </Box>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            <Skeleton
              variant="text"
              height={"32px"}
              width={"180px"}
              // sx={{ borderRadius: "8px" }}
            ></Skeleton>
            <Skeleton
              variant="rectangular"
              height={"44px"}
              width={"100%"}
              sx={{ borderRadius: "32px" }}
            ></Skeleton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
